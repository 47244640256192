import { getMenuItems } from "./MenuItems"

export function getConfig() {
  const menuItems = getMenuItems()

  return {
    logo: "https://www.entelco.com.br/admin/view/image/logo.png",
    primaryColor: "",
    secondaryColor: "",
    companyName: "CSG",
    menuItems: menuItems,
  }
}
