export class AllowedIp {
  id: number
  ip: string
  createdAt: Date

  constructor(data: any) {
    this.id = data._id
    this.ip = data.ip
    this.createdAt = data.createdAt
  }
}
