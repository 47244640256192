import { SearchParams, SearchResult } from "../../entities"
import { AllowedIp } from "../../entities/csg/AllowedIp"
import { AllowedIpRepository } from "../../repositories/csg/AllowedIpRepository"
import UserStorage from "../../util/userStorage"

export class AllowedIpService {
  public static async search(
    params: SearchParams
  ): Promise<SearchResult<AllowedIp>> {
    const token = UserStorage.getToken()

    const result = await AllowedIpRepository.search(params, token)
    return result
  }

  public static async get(id: number): Promise<AllowedIp> {
    const token = UserStorage.getToken()
    return await AllowedIpRepository.get(id, token)
  }

  public static async create(data: AllowedIp): Promise<AllowedIp> {
    const token = UserStorage.getToken()
    return await AllowedIpRepository.create(data, token)
  }

  public static async update(data: AllowedIp): Promise<AllowedIp> {
    const token = UserStorage.getToken()
    return await AllowedIpRepository.update(data, token)
  }

  public static async delete(id: string): Promise<boolean> {
    const token = UserStorage.getToken()
    return await AllowedIpRepository.delete(id, token)
  }
}
