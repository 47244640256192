import { Flex, Text } from "@chakra-ui/react"
import { IconType } from "react-icons"

type DashboardCardInformationProps = {
  label: string
  value: string | number
  Icon: IconType
}

export function DashboardCardInformation({
  label,
  value,
  Icon,
}: DashboardCardInformationProps) {
  return (
    <Flex
      w="100%"
      bg="#fff"
      borderRadius="1rem"
      p="1rem 2rem"
      justifyContent={{
        base: "center",
        sm: "flex-start",
        md: "flex-start",
        lg: "flex-start",
      }}
      gap="1rem"
      flexWrap={{
        base: "wrap",
        sm: "nowrap",
        md: "nowrap",
        lg: "nowrap",
      }}
    >
      <Flex
        bg="#E7ECFF"
        h="72px"
        w="72px"
        borderRadius="50%"
        justifyContent="center"
        alignItems="center"
        fontSize="2.5rem"
      >
        <Icon />
      </Flex>

      <Flex
        direction="column"
        alignItems={{
          base: "center",
          sm: "normal",
          md: "normal",
          lg: "normal",
        }}
      >
        <Text
          fontSize="0.875rem"
          fontWeight="700"
          color="#8E99AB"
          fontFamily="DM Sans"
        >
          {label}
        </Text>
        <Text
          fontSize="2.38rem"
          fontWeight="700"
          color="#2D3748"
          fontFamily="DM Sans"
          textAlign={{
            base: "center",
            sm: "inherit",
            md: "inherit",
            lg: "inherit",
          }}
        >
          {value}
        </Text>
      </Flex>
    </Flex>
  )
}
