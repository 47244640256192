import React, { useEffect, useState } from "react"
import {
  Button,
  Flex,
  FormControl,
  Input,
  Text,
  useToast,
  useDisclosure,
} from "@chakra-ui/react"
import {
  SearchFilter,
  SearchParams,
  SearchResultInfo,
} from "../../../../entities"
import Layout from "../../../../components/layout/Layout"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import DataTable from "../../../../components/dataTable/DataTable"
import Loader from "../../../../components/Loader/Loader"
import { BsCash } from "react-icons/bs"
import { AllowedIp } from "../../../../entities/csg/AllowedIp"
import { AllowedIpService } from "../../../../services/csg/AllowedIpService"
import { DeleteModal } from "../../../../components/shared/delete-modal"
import AllowedIpFormModal from "./AllowedIpFormModal"
import UserStorage from "../../../../util/userStorage"

export default function AllowedIpListPage() {
  const toast = useToast()
  const user = UserStorage.get()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [allowedIps, setAllowedIps] = useState<AllowedIp[]>([])
  const [resultInfo, setResultInfo] = useState(
    new SearchResultInfo({ pageSize: 20 })
  )
  const [loading, setLoading] = useState(false)

  const [ip, setIp] = useState("")

  useEffect(() => {
    load()
    // eslint-disable-next-line
  }, [])

  const getFilters = (): SearchFilter[] => {
    const filters: SearchFilter[] = []
    if (ip) {
      filters.push(
        new SearchFilter({
          key: "ip",
          value: ip,
          operator: "eq",
        })
      )
    }

    return filters
  }

  const load = async (page = 1) => {
    const filters = getFilters()
    const searchParams = new SearchParams(filters, page, 10)

    setLoading(true)
    AllowedIpService.search(searchParams)
      .then((result) => {
        setAllowedIps(result.data)
        setResultInfo(result.info)
      })
      .finally(() => setLoading(false))
  }

  const remove = (id: string) => {
    AllowedIpService.delete(id)
      .then(() => {
        toast({
          title: "IP removido com sucesso.",
          status: "success",
          duration: 2000,
        })
        load()
      })
      .catch((error) => {
        console.log("error", error?.response?.data?.message)
        if (error?.response?.data?.message) {
          toast({
            title: error?.response?.data?.message,
            status: "error",
            duration: 2000,
            position: "top",
          })
        }
      })
  }

  const renderActions = (params: any) => {
    return (
      <div>
        <DeleteModal handleDelete={() => remove(params.id)} />
      </div>
    )
  }

  const columns = [
    { field: "ip", headerName: "IP", mWidth: "40%" },
    {
      headerName: "Ações",
      renderCell: renderActions,
      mWidth: "65%",
      mAlign: "end",
      mDisplay: "none",
    },
  ]

  return (
    <Layout>
      {loading && <Loader />}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        mb="3rem"
      >
        <Flex direction="column">
          <Breadcrumbs
            items={[
              { label: "Dashboard", link: "/" },
              { label: "Liberação de IPs para o Painel", link: "/allowed-ip" },
            ]}
          />
          <Flex gap="0.75rem" alignItems="center">
            <Flex fontSize="2rem" fontWeight="700" color="#114B8F">
              <BsCash />
            </Flex>
            <Text
              fontSize={{
                base: "1.5rem",
                sm: "1.75rem",
                md: "2rem",
                lg: "2.25rem",
              }}
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Liberação de IPs para o Painel
            </Text>
          </Flex>
        </Flex>
        <Flex
          w="auto"
          ml="auto"
          alignSelf="flex-end"
          justifyContent={{
            base: "flex-end",
            sm: "flex-end",
            md: "flex-end",
            lg: "flex-end",
          }}
          gap="1.25rem"
          alignItems="flex-end"
          direction={{ base: "column", sm: "row", md: "row", lg: "row" }}
        >
          <FormControl
            w="100%"
            maxW={{ base: "100%", sm: "100%", md: "100%", lg: "648px" }}
            isInvalid={false}
            display="flex"
            justifyContent="flex-end"
            gap="0.5rem"
            flexWrap="wrap"
          >
            <Input
              maxW={{ base: "100%", sm: "158px", md: "158px", lg: "158px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              placeholder="IP"
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              name="ip"
              value={ip}
              onChange={(e) => setIp(e.target.value)}
            />
          </FormControl>
          <Flex gap="1rem">
            <Button
              minW="95px"
              size="md"
              borderRadius="0.375rem"
              bg="#00335B"
              fontFamily="Raleway"
              fontSize="0.875rem"
              fontWeight="600"
              color="#fff"
              _hover={{ bg: "#00335B" }}
              onClick={() => load(1)}
            >
              Buscar
            </Button>

            {user?.role.code === "admin" && (
              <Button
                minW="95px"
                size="md"
                borderRadius="0.375rem"
                bg="#00335B"
                fontFamily="Raleway"
                fontSize="0.875rem"
                fontWeight="600"
                color="#fff"
                _hover={{ bg: "#00335B" }}
                onClick={() => onOpen()}
              >
                Adicionar
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
      <DataTable
        checkboxSelection={false}
        rows={allowedIps}
        columns={columns}
        pagination={resultInfo}
        onPageChange={(page: number) => load(page)}
        loading={loading}
      />

      <AllowedIpFormModal
        load={load}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
    </Layout>
  )
}
