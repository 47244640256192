import React, { useEffect, useState } from "react"
import Layout from "../../../components/layout/Layout"
import { Flex, Grid, GridItem, Text } from "@chakra-ui/react"
import { Dashboard } from "../../../entities/csg/Dashboard"
import { DashboardService } from "../../../services/csg/DashboardService"
import Loader from "../../../components/Loader/Loader"
import { useNavigate } from "react-router-dom"
import UserStorage from "../../../util/userStorage"
import Breadcrumbs from "../../../components/layout/breadcrumbs/Breadcrumbs"
import {
  BsCalendar2DateFill,
  BsCalendar4Week,
  BsCarFrontFill,
  BsHouseDoorFill,
  BsPersonFill,
  BsReplyAllFill,
  BsReplyFill,
} from "react-icons/bs"
import { PiVaultFill } from "react-icons/pi"
import DataTable from "../../../components/dataTable/DataTable"
import "./DashboardPage.scss"
import { UserService } from "../../../services"
import Formatter from "../../../util/formatter"
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { DashboardCardInformation } from "../../../components/shared/dashboard-card-information"

export default function DashboardPage() {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [dashboardData, setDashboardData] = useState<Dashboard>(new Dashboard())

  useEffect(() => {
    UserService.isValidUser().then((isValid) => {
      if (!isValid) {
        UserStorage.logout()
        navigate("/login")
        return
      }
      load()
    })
    // eslint-disable-next-line
  }, [])

  const load = () => {
    setIsLoading(true)
    DashboardService.get()
      .then((dash) => {
        console.log(dash)
        setDashboardData(dash)
      })
      .finally(() => setIsLoading(false))
  }

  const renderToday = (params: any) => {
    return Formatter.formatCurrency(params.today)
  }

  const renderD1 = (params: any) => {
    return Formatter.formatCurrency(params.d1)
  }

  const renderD2 = (params: any) => {
    return Formatter.formatCurrency(params.d2)
  }

  const renderD3 = (params: any) => {
    return Formatter.formatCurrency(params.d3)
  }

  const renderD4 = (params: any) => {
    return Formatter.formatCurrency(params.d4)
  }

  const renderD5 = (params: any) => {
    return Formatter.formatCurrency(params.d5)
  }

  const renderD6 = (params: any) => {
    return Formatter.formatCurrency(params.d6)
  }

  const renderD15 = (params: any) => {
    return Formatter.formatCurrency(params.d15)
  }

  const renderMonth = (params: any) => {
    return Formatter.formatCurrency(params.month)
  }

  const renderM1 = (params: any) => {
    return Formatter.formatCurrency(params.m1)
  }

  const renderM2 = (params: any) => {
    return Formatter.formatCurrency(params.m2)
  }

  const columns = [
    { field: "type", headerName: "", mWidth: "18%" },
    { renderCell: renderToday, headerName: "Dia Atual", mWidth: "18%" },
    { renderCell: renderD1, headerName: "D-1", mWidth: "18%" },
    { renderCell: renderD2, headerName: "D-2", mWidth: "18%" },
    { renderCell: renderD3, headerName: "D-3", mWidth: "18%" },
    { renderCell: renderD4, headerName: "D-4", mWidth: "18%" },
    { renderCell: renderD5, headerName: "D-5", mWidth: "18%" },
    { renderCell: renderD6, headerName: "D-6", mWidth: "18%" },
    { renderCell: renderD15, headerName: "D-15", mWidth: "18%" },
    { renderCell: renderMonth, headerName: "Mês Atual", mWidth: "18%" },
    { renderCell: renderM1, headerName: "Mês-1", mWidth: "18%" },
    { renderCell: renderM2, headerName: "Mês-2", mWidth: "18%" },
  ]

  const renderTodayQty = (params: any) => {
    return Formatter.formatNumber(params.today)
  }

  const renderD1Qty = (params: any) => {
    return Formatter.formatNumber(params.d1)
  }

  const renderD2Qty = (params: any) => {
    return Formatter.formatNumber(params.d2)
  }

  const renderD3Qty = (params: any) => {
    return Formatter.formatNumber(params.d3)
  }

  const renderD4Qty = (params: any) => {
    return Formatter.formatNumber(params.d4)
  }

  const renderD5Qty = (params: any) => {
    return Formatter.formatNumber(params.d5)
  }

  const renderD6Qty = (params: any) => {
    return Formatter.formatNumber(params.d6)
  }

  const renderD15Qty = (params: any) => {
    return Formatter.formatNumber(params.d15)
  }

  const renderMonthQty = (params: any) => {
    return Formatter.formatNumber(params.month)
  }

  const renderM1Qty = (params: any) => {
    return Formatter.formatNumber(params.m1)
  }

  const renderM2Qty = (params: any) => {
    return Formatter.formatNumber(params.m2)
  }

  const columnsQty = [
    { field: "type", headerName: "", mWidth: "18%" },
    { renderCell: renderTodayQty, headerName: "Dia Atual", mWidth: "18%" },
    { renderCell: renderD1Qty, headerName: "D-1", mWidth: "18%" },
    { renderCell: renderD2Qty, headerName: "D-2", mWidth: "18%" },
    { renderCell: renderD3Qty, headerName: "D-3", mWidth: "18%" },
    { renderCell: renderD4Qty, headerName: "D-4", mWidth: "18%" },
    { renderCell: renderD5Qty, headerName: "D-5", mWidth: "18%" },
    { renderCell: renderD6Qty, headerName: "D-6", mWidth: "18%" },
    { renderCell: renderD15Qty, headerName: "D-15", mWidth: "18%" },
    { renderCell: renderMonthQty, headerName: "Mês Atual", mWidth: "18%" },
    { renderCell: renderM1Qty, headerName: "Mês-1", mWidth: "18%" },
    { renderCell: renderM2Qty, headerName: "Mês-2", mWidth: "18%" },
  ]

  //SALDO, CRÉDITO, DÉBITO, PIX, DINHEIRO, VVP, TOTEM CRÉDITO, TOTEM DÉBITO, TOTEM PIX, TOTAL
  //EM ABERTO, TOTAL
  //PAGAS, EM ABERTO, TOTAL

  return (
    <Layout isHome={true}>
      <Flex w="100%" flexDirection="column" gap="1rem">
        <Flex bg="#fff" borderRadius="1rem" p="1rem" direction="column">
          {isLoading && <Loader />}
          <Breadcrumbs items={[{ label: "Dashboard", link: "/" }]} />
          <Text
            fontSize={{ base: "1.75rem", md: "2.25rem" }}
            fontWeight="700"
            color="#114B8F"
            fontFamily="DM Sans"
            display="flex"
            gap="1rem"
            alignItems="center"
          >
            <BsHouseDoorFill />
            Dashboard
          </Text>
        </Flex>

        <Grid
          w="100%"
          gap="1rem"
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
          }}
          className="dashboard-icon-1"
          justifyContent="space-between"
        >
          <GridItem>
            <DashboardCardInformation
              Icon={BsCarFrontFill}
              label="Qtd. de cadastros atual"
              value={Formatter.formatNumber(dashboardData.numberOfClients)}
            />
          </GridItem>

          <GridItem>
            <DashboardCardInformation
              Icon={BsCarFrontFill}
              label="Qtd. de veículos cadastrados"
              value={Formatter.formatNumber(
                dashboardData.vehiclesActiveAndRegular
              )}
            />
          </GridItem>
        </Grid>

        <Grid
          w="100%"
          gap="1rem"
          templateColumns={{
            base: "repeat(1, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          className="dashboard-icon-1"
          justifyContent="space-between"
        >
          <GridItem>
            <DashboardCardInformation
              Icon={BsCalendar2DateFill}
              label="Mês atual"
              value={Formatter.formatNumber(
                dashboardData.numberOfClientsThisMonth
              )}
            />
          </GridItem>

          <GridItem>
            <DashboardCardInformation
              Icon={BsReplyFill}
              label="Mês Anterior"
              value={Formatter.formatNumber(
                dashboardData.numberOfClientsLastMonth
              )}
            />
          </GridItem>

          <GridItem>
            <DashboardCardInformation
              Icon={BsReplyFill}
              label="Mês retrasado"
              value={Formatter.formatNumber(
                dashboardData.numberOfClientsTwoMonthAgo
              )}
            />
          </GridItem>
        </Grid>

        <Grid
          w="100%"
          gap="1rem"
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
          }}
          className="dashboard-icon-1"
          justifyContent="space-between"
        >
          <GridItem>
            <DashboardCardInformation
              Icon={PiVaultFill}
              label="Clientes com saldo em custódia"
              value={Formatter.formatNumber(dashboardData.customersWithBalance)}
            />
          </GridItem>

          <GridItem>
            <DashboardCardInformation
              Icon={PiVaultFill}
              label="Saldo atual em custódia"
              value={Formatter.formatCurrency(dashboardData.custodyThisMonth)}
            />
          </GridItem>

          <GridItem>
            <DashboardCardInformation
              Icon={BsCalendar2DateFill}
              label="Custódia do mês anterior"
              value={Formatter.formatCurrency(dashboardData.custodyLastMonth)}
            />
          </GridItem>

          <GridItem>
            <DashboardCardInformation
              Icon={BsReplyFill}
              label="Custódia do mês retrasado"
              value={Formatter.formatCurrency(dashboardData.custodyTwoMonthAgo)}
            />
          </GridItem>
        </Grid>

        <Flex
          w="100%"
          gap="1rem"
          flexWrap="wrap"
          direction={{ base: "column", lg: "row" }}
          className="dashboard-icon-3"
          justifyContent="space-between"
        >
          <Flex
            flex="2"
            minW={{ base: "100%", lg: "60%" }}
            minH={{ lg: "450px" }}
            bg="#fff"
            borderRadius="1rem"
            p="1rem 2rem"
            direction="column"
            alignItems="center"
          >
            <Text
              fontSize={{ base: "1rem", md: "1.25rem" }}
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
              textAlign="center"
            >
              Passagens dos últimos 12 meses
            </Text>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={dashboardData.chargeCounts}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#f26522" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#f26522" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                <XAxis dataKey="label" tick={{ fontSize: 12, fill: "#666" }} />
                <YAxis tick={{ fontSize: 12, fill: "#666" }} />
                <Tooltip />
                <Area
                  type="monotone"
                  name="Passagem"
                  dataKey="count"
                  stroke="#f26522"
                  fill="url(#colorUv)"
                  fillOpacity={0.3}
                />
                <Legend />
              </AreaChart>
            </ResponsiveContainer>
          </Flex>

          <Flex
            flex="1"
            minW={{ base: "100%", lg: "35%" }}
            direction="column"
            gap="1rem"
          >
            <DashboardCardInformation
              Icon={BsCalendar2DateFill}
              label="Passagens de hoje"
              value={Formatter.formatNumber(dashboardData.passesToday)}
            />

            <DashboardCardInformation
              Icon={BsReplyFill}
              label="Dia anterior"
              value={Formatter.formatNumber(dashboardData.passesYesterday)}
            />

            <DashboardCardInformation
              Icon={BsCalendar4Week}
              label="Passagens semana anterior"
              value={Formatter.formatNumber(dashboardData.passesLastWeek)}
            />

            <DashboardCardInformation
              Icon={BsCalendar2DateFill}
              label="Passagens mês anterior"
              value={Formatter.formatNumber(dashboardData.passesLastMonth)}
            />
          </Flex>
        </Flex>

        <Flex w="100%">
          <Flex
            w="100%"
            bg="#fff"
            borderRadius="1rem"
            p="1rem 2rem"
            direction="column"
            gap="2rem"
          >
            <Text
              fontSize="1.25rem"
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Pagamentos realizados
            </Text>
            <DataTable
              //title="Clientes"
              checkboxSelection={false}
              rows={dashboardData.payments}
              columns={columns}
              //pagination={resultInfo}
              //onPageChange={(page: number) => load(page)}
              //loading={loading}
            />
          </Flex>
        </Flex>

        <Flex w="100%">
          <Flex
            w="100%"
            bg="#fff"
            borderRadius="1rem"
            p="1rem 2rem"
            direction="column"
            gap="2rem"
          >
            <Text
              fontSize="1.25rem"
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Passagens pendentes de pagamento (valor)
            </Text>
            <DataTable
              //title="Clientes"
              checkboxSelection={false}
              rows={dashboardData.openChargesTotal}
              columns={columns}
              //pagination={resultInfo}
              //onPageChange={(page: number) => load(page)}
              //loading={loading}
            />
          </Flex>
        </Flex>

        <Flex w="100%">
          <Flex
            w="100%"
            bg="#fff"
            borderRadius="1rem"
            p="1rem 2rem"
            direction="column"
            gap="2rem"
          >
            <Text
              fontSize="1.25rem"
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Passagens (quantidade)
            </Text>
            <DataTable
              //title="Clientes"
              checkboxSelection={false}
              rows={dashboardData.chargesTotal}
              columns={columnsQty}
              //pagination={resultInfo}
              //onPageChange={(page: number) => load(page)}
              //loading={loading}
            />
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  )
}
