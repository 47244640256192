export class SecurityBlocker {
  public id: string
  public type: string
  public ip?: string
  public userId?: number
  public userEmail?: string
  public times: number
  public lastBlockAt?: Date
  public expiresAt?: Date
  public createdAt: Date

  public constructor(data: any) {
    this.id = data._id
    this.type = data.type
    this.ip = data.ip
    this.userId = data.userId
    this.userEmail = data.userEmail
    this.times = data.times
    if (data.lastBlockAt) this.lastBlockAt = new Date(data.lastBlockAt)
    if (data.expiresAt) this.expiresAt = new Date(data.expiresAt)
    this.createdAt = new Date(data.createdAt)
  }
}
