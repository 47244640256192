import React, { useEffect, useState } from "react"
import Layout from "../../../../components/layout/Layout"
import DataTable from "../../../../components/dataTable/DataTable"
import {
  Button,
  Flex,
  FormControl,
  Input,
  Select,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react"
import {
  SearchParams,
  SearchResultInfo,
  SearchFilter,
  SecurityBlocker,
} from "../../../../entities"
import { SecurityService } from "../../../../services"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import {
  BsCheckCircle,
  BsFillShieldFill,
  BsTrash3,
  BsXCircle,
} from "react-icons/bs"
import Formatter from "../../../../util/formatter"
import Loader from "../../../../components/Loader/Loader"
import SecurityFormModal from "./SecurityFormModal"
import UserStorage from "../../../../util/userStorage"

export default function SecurityListPage() {
  const user = UserStorage.get()
  const [securityBlockers, setSecurityBlockers] = useState<SecurityBlocker[]>(
    []
  )
  const [resultInfo, setResultInfo] = useState(new SearchResultInfo())
  const [loading, setLoading] = useState(false)
  const [ip, setIp] = useState("")
  const [email, setEmail] = useState("")
  const [userId, setUserId] = useState("")
  const [type, setType] = useState("")

  const showSecurityFormDisclosure = useDisclosure()

  useEffect(() => {
    load()
    // eslint-disable-next-line
  }, [])

  const load = async (page = 1) => {
    setLoading(true)

    const filters: SearchFilter[] = []
    if (ip) {
      filters.push(
        new SearchFilter({
          key: "ip",
          value: ip,
          operator: "like",
        })
      )
    }

    if (type) {
      filters.push(
        new SearchFilter({ key: "type", value: type, operator: "eq" })
      )
    }

    if (email) {
      filters.push(
        new SearchFilter({ key: "email", value: email, operator: "eq" })
      )
    }

    if (userId) {
      filters.push(
        new SearchFilter({ key: "userId", value: userId, operator: "eq" })
      )
    }

    const params = new SearchParams(filters, page, 10)

    setLoading(true)
    SecurityService.search(params)
      .then((result) => {
        setSecurityBlockers(result.data)
        setResultInfo(result.info)
      })
      .finally(() => setLoading(false))
  }

  const chagneStatus = async (
    securityBlocker: SecurityBlocker,
    newStatus: string
  ) => {
    setLoading(true)
    SecurityService.changeStatus(securityBlocker, newStatus)
      .then((result) => {
        load()
      })
      .catch((error) => setLoading(false))
  }

  const renderActions = (params: any) => {
    const couldAllow = params.type !== "allowed"
    const couldBlock = params.type !== "forever"

    return (
      <Flex gap="1rem" flexWrap="wrap" alignItems="center">
        {couldAllow && (
          <Tooltip
            label="Liberar IP/usuário permanentemente."
            aria-label="A tooltip"
          >
            <Button
              size="md"
              bg="#E7ECFF"
              color="#00335B"
              transition="0.2s"
              _hover={{ bg: "#114B8F", color: "#fff" }}
              onClick={() => chagneStatus(params, "allow")}
              display="flex"
              gap="0.5rem"
              alignItems="center"
            >
              <BsCheckCircle /> Liberar
            </Button>
          </Tooltip>
        )}
        {couldBlock && (
          <Tooltip
            label="Bloquear IP/usuário permanentemente."
            aria-label="A tooltip"
          >
            <Button
              size="md"
              bg="#E7ECFF"
              color="#00335B"
              transition="0.2s"
              _hover={{ bg: "#114B8F", color: "#fff" }}
              onClick={() => chagneStatus(params, "block")}
              display="flex"
              gap="0.5rem"
              alignItems="center"
            >
              <BsXCircle /> Bloquear
            </Button>
          </Tooltip>
        )}
        <Tooltip
          label="Remover IP/usuário da lista de bloquados."
          aria-label="A tooltip"
        >
          <Button
            size="md"
            bg="#E7ECFF"
            color="#00335B"
            transition="0.2s"
            _hover={{ bg: "#114B8F", color: "#fff" }}
            onClick={() => chagneStatus(params, "remove")}
            display="flex"
            gap="0.5rem"
            alignItems="center"
          >
            <BsTrash3 /> Remover
          </Button>
        </Tooltip>
      </Flex>
    )
  }

  const renderStatus = (params: any) => {
    return <>{Formatter.formatBlockType(params.type)}</>
  }

  const renderLastBlockAt = (params: any) => {
    return (
      <>
        {params.lastBlockAt
          ? Formatter.formatDateTime(new Date(params.lastBlockAt))
          : ""}
      </>
    )
  }

  const renderExpiresAt = (params: any) => {
    return (
      <>
        {params.expiresAt
          ? Formatter.formatDateTime(new Date(params.expiresAt))
          : ""}
      </>
    )
  }

  const columns = [
    { field: "ip", headerName: "IP", mWidth: "100%" },
    { field: "userEmail", headerName: "Usuário", mWidth: "100%" },
    { field: "times", headerName: "Penalidades", mWidth: "100%" },
    {
      renderCell: renderLastBlockAt,
      headerName: "Último Bloqueio",
      mWidth: "60%",
    },
    { renderCell: renderExpiresAt, headerName: "Expira em", mWidth: "60%" },
    { renderCell: renderStatus, headerName: "Status", mWidth: "35%" },
    {
      headerName: "Ações",
      renderCell: renderActions,
      mWidth: "100%",
    },
  ]

  return (
    <Layout>
      {loading && <Loader />}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={{ base: "wrap", sm: "wrap", md: "nowrap", lg: "nowrap" }}
        mb="3rem"
      >
        <Flex direction="column">
          <Breadcrumbs
            items={[
              { label: "Dashboard", link: "/" },
              { label: "Segurança", link: "/security" },
            ]}
            additionalHeaderInfo={<></>}
          />
          <Flex gap="0.75rem" alignItems="center">
            <Flex fontSize="2rem" fontWeight="700" color="#114B8F">
              <BsFillShieldFill />
            </Flex>
            <Text
              fontSize={{
                base: "1.5rem",
                sm: "1.75rem",
                md: "2rem",
                lg: "2.25rem",
              }}
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Segurança
            </Text>
          </Flex>
        </Flex>
        <Flex w="100%" direction="column" gap="0.25rem" alignItems="flex-end">
          {/* <Text
            w="100%"
            maxW="438px"
            fontFamily="DM Sans"
            fontSize="0.75rem"
            fontWeight="500"
            color="#8E99AB"
          >
            ADICIONAR IP
          </Text> */}
          <Flex
            w="100%"
            maxW={{ base: "none", sm: "none", md: "537px", lg: "537px" }}
            justifyContent={{
              base: "flex-end",
              sm: "flex-end",
              md: "none",
              lg: "none",
            }}
            gap="1.25rem"
            alignItems="flex-end"
            direction={{ base: "column", sm: "row", md: "row", lg: "row" }}
          >
            <FormControl
              w="100%"
              maxW="422px"
              isInvalid={false}
              display="flex"
              flexWrap="wrap"
              justifyContent="flex-end"
              gap="0.5rem"
            >
              <Input
                maxW={{ base: "100%", sm: "143px", md: "143px", lg: "143px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD" }}
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                type="text"
                name="ip"
                placeholder="IP"
                value={ip}
                onChange={(e) => setIp(e.target.value)}
              />
              <Input
                maxW={{ base: "100%", sm: "143px", md: "143px", lg: "143px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD" }}
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                type="text"
                name="ip"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                maxW={{ base: "100%", sm: "143px", md: "143px", lg: "143px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD" }}
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                type="text"
                name="ip"
                placeholder="ID do Usuário"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              />
              <Select
                maxW={{ base: "100%", sm: "175px", md: "175px", lg: "175px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD" }}
                placeholder="Todos os status"
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="allowed">Permitido</option>
                <option value="temporary">Bloqueio Temporário</option>
                <option value="forever">Bloqueado</option>
              </Select>
            </FormControl>
            <Button
              minW="95px"
              size="md"
              borderRadius="0.375rem"
              bg="#00335B"
              fontFamily="Raleway"
              fontSize="0.875rem"
              fontWeight="600"
              color="#fff"
              _hover={{ bg: "#00335B" }}
              onClick={() => load(1)}
            >
              Buscar
            </Button>
            {user?.role?.code === "admin" && (
              <Button
                minW="95px"
                size="md"
                borderRadius="0.375rem"
                bg="#00335B"
                fontFamily="Raleway"
                fontSize="0.875rem"
                fontWeight="600"
                color="#fff"
                _hover={{ bg: "#00335B" }}
                onClick={() => showSecurityFormDisclosure.onOpen()}
              >
                Adicionar
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
      <DataTable
        checkboxSelection={false}
        rows={securityBlockers}
        columns={columns}
        pagination={resultInfo}
        onPageChange={(page: number) => load(page)}
        loading={loading}
      />
      <SecurityFormModal
        load={load}
        isOpen={showSecurityFormDisclosure.isOpen}
        onOpen={showSecurityFormDisclosure.onOpen}
        onClose={showSecurityFormDisclosure.onClose}
      />
    </Layout>
  )
}
