import { SearchParams, SearchResult } from "../../entities"
import { AllowedIp } from "../../entities/csg/AllowedIp"
import QueryStringBuilder from "../../util/queryBuilder"
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class AllowedIpRepository {
  public static async search(
    params: SearchParams,
    token: string
  ): Promise<SearchResult<AllowedIp>> {
    const headers = { Authorization: `Bearer ${token}` }
    const queryStringBuilder = new QueryStringBuilder()
    for (const filter of params.filter) {
      queryStringBuilder.addParameter(filter.key, filter.value)
    }
    const queryString = queryStringBuilder.buildQueryString()

    const response = await client.DoRequest(
      "GET",
      `/allowed-ip?${queryString}&page=${params.page}&limit=${params.limit}`,
      {},
      headers,
      false
    )

    const data = response.data.map((item: any) => {
      return new AllowedIp(item)
    })

    const result = new SearchResult<AllowedIp>(data, response.info)

    return result
  }

  public static async get(id: number, token: string): Promise<AllowedIp> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest(
      "GET",
      `/allowed-ip/${id}`,
      {},
      headers
    )

    return new AllowedIp(response)
  }

  public static async create(
    data: AllowedIp,
    token: string
  ): Promise<AllowedIp> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "POST",
      "/allowed-ip",
      data,
      headers
    )

    return new AllowedIp(response)
  }

  public static async update(
    data: AllowedIp,
    token: string
  ): Promise<AllowedIp> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "PUT",
      `/allowed-ip/${data.id}`,
      data,
      headers
    )

    return new AllowedIp(response)
  }

  public static async delete(id: string, token: string): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "DELETE",
      `/allowed-ip/${id}`,
      {},
      headers
    )

    return response === true
  }
}
