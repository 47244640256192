import { useState } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  useToast,
  Flex,
} from "@chakra-ui/react"
import { AllowedIpService } from "../../../../services/csg/AllowedIpService"
import { AllowedIp } from "../../../../entities/csg/AllowedIp"

interface Props {
  load(): any
  isOpen?: any
  onOpen?: any
  onClose?: any
}

export default function AllowedIpFormModal(props: Props) {
  const toast = useToast()

  const [ip, setIp] = useState("")

  const save = () => {
    const data = new AllowedIp({ ip, createdAt: new Date() })

    if (ip === "") {
      toast({
        title: "Digite o IP.",
        status: "error",
        duration: 2000,
      })
      return
    }

    AllowedIpService.create(data)
      .then((success) => {
        toast({
          title: "Ip adicionado com sucesso.",
          status: "success",
          duration: 2000,
        })

        setIp("")

        props.load()
        props.onClose()
      })
      .catch((error) => {
        console.log("error", error?.response?.data?.message)
        if (error?.response?.data?.message) {
          toast({
            title: error?.response?.data?.message,
            status: "error",
            duration: 2000,
            position: "top",
          })
        }
      })
  }

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onClose={props.onClose}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adicionar IP</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" gap="1rem">
              <FormControl
                w="100%"
                isInvalid={false}
                display="flex"
                flexWrap="wrap"
                justifyContent="flex-start"
                gap="0.5rem"
              >
                <Input
                  size="sm"
                  fontFamily="Raleway"
                  fontSize="0.75rem"
                  fontWeight="400"
                  _placeholder={{ color: "#A2ACBD" }}
                  border="1px solid #E2E8F0"
                  borderRadius="0.25rem"
                  type="text"
                  name="ip"
                  placeholder="Informe o IP"
                  value={ip}
                  onChange={(e) => setIp(e.target.value)}
                />
              </FormControl>
              <Button onClick={save}>Salvar</Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
